define("mgw/templates/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IGa3oock",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content-white height-100\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex justify-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex column padding-top-11\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"class\",\"route\"],[\"nav-btn btn-big\",\"project-widgets\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"icon icon--dashboard\"],[12],[13],[2,\"\\n        \"],[1,[30,[36,0],[\"projectWidgets\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],null,[[\"class\",\"route\"],[\"nav-btn btn-big\",\"projects-list\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"icon icon--list\"],[12],[13],[2,\"\\n        \"],[1,[30,[36,0],[\"projectsList\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],null,[[\"class\",\"route\"],[\"nav-btn btn-big\",\"project-templates\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"icon icon--pencil\"],[12],[13],[2,\"\\n        \"],[1,[30,[36,0],[\"projectTemplates\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],null,[[\"class\",\"route\"],[\"nav-btn btn-big\",\"users\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"icon icon--user\"],[12],[13],[2,\"\\n        \"],[1,[30,[36,0],[\"users\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],null,[[\"class\",\"route\"],[\"nav-btn btn-big\",\"organizations\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"icon icon--organization\"],[12],[13],[2,\"\\n        \"],[1,[30,[36,0],[\"organizations\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\"]}",
    "meta": {
      "moduleName": "mgw/templates/dashboard.hbs"
    }
  });

  _exports.default = _default;
});