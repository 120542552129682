define("mgw/controllers/login", ["exports", "mgw/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    email: '',
    queryParams: ['email'],
    failedToAutoLogin: false,
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    deviceservice: Ember.inject.service(),
    passwordservice: Ember.inject.service(),
    ssoproviderservice: Ember.inject.service(),
    session: Ember.inject.service(),
    companyId: '',
    showStartScreenForFreshAppInstalls: Ember.computed('failedToAutoLogin', function () {
      return !this.get('deviceservice').iOS && !this.failedToAutoLogin && window.navigator.userAgent.includes('cordova') && !document.cookie.includes('deviceHasBeenLoggedInAtLeastOnce=true');
    }),
    showResetPassword: Ember.computed.alias('passwordservice.clusterConfig.isSmtpEnabled'),
    showSSO: !_environment.default.onPremise,
    showHelp: !_environment.default.onPremise,
    visibleIdProviders: Ember.computed('ssoproviderservice.ssoProviders', function () {
      var _this$get;

      return ((_this$get = this.get('ssoproviderservice.ssoProviders')) === null || _this$get === void 0 ? void 0 : _this$get.rejectBy('hidden')) || [];
    }),
    parseCompanyId: function parseCompanyId(companyId) {
      return companyId.replace(/[^a-zA-Z0-9]/g, '').trim().toLowerCase();
    },
    getIdProviderUsingCompanyId: function getIdProviderUsingCompanyId(companyId) {
      for (var i = 0; i < this.get('ssoproviderservice.ssoProviders').length; i++) {
        /* eslint-disable-next-line prefer-const */
        var provider = this.get('ssoproviderservice.ssoProviders')[i];

        if (this.parseCompanyId(provider.id) === this.parseCompanyId(companyId)) {
          return provider.id;
        }
      }

      return null;
    },
    forceFederationFromProviders: [],
    forcePassword: false,
    actions: {
      hideNativeStartScreen: function hideNativeStartScreen() {
        this.set('showStartScreenForFreshAppInstalls', false);
      },
      createNewProject: function createNewProject() {
        window.open(this.intl.t('createNewProjectURL'), '_blank', 'location=no');
      },
      transitionAfterLogin: function transitionAfterLogin() {
        document.cookie = 'deviceHasBeenLoggedInAtLeastOnce=true; path=/';
        this.router.transitionTo('index');
      },
      switchRegion: function switchRegion() {
        window.location.href = 'https://global.streambim.com?preventRedirect=true';
      },
      loginUsing: function loginUsing(provider, email) {
        var landingPageUrl = this.get('ssoproviderservice.ssoProviders').findBy('id', provider).landingPage;

        if (landingPageUrl) {
          if (this.get('deviceservice').onNative) {
            window.open(landingPageUrl, '_system');
          } else {
            window.location.href = landingPageUrl;
          }
        } else {
          var url = '/mgw/api/v2/federation/redirecttoexternallogin?provider=' + provider.toUpperCase();

          if (email) {
            url += '&login_hint=' + email;
          }

          if (this.get('deviceservice').onNative) {
            var fullUrl = window.location.origin + url;
            window.open(fullUrl, '_system');
          } else {
            window.location.href = url;
          }
        }
      },
      loginUsingPassword: function loginUsingPassword() {
        this.set('forcePassword', true);
      },
      loginUsingSSO: function loginUsingSSO(companyId) {
        /* eslint-disable-next-line prefer-const */
        var idProviderId = this.getIdProviderUsingCompanyId(companyId);

        if (idProviderId) {
          this.set('invalidCompanyId', false);
          this.send('loginUsing', idProviderId);
        } else {
          this.set('invalidCompanyId', true);
        }
      },
      forceFederationFromProvidersDidChange: function forceFederationFromProvidersDidChange(forceFederationFromProviders) {
        this.set('forcePassword', false);
        this.forceFederationFromProviders.setObjects(forceFederationFromProviders);
      }
    }
  });

  _exports.default = _default;
});