define("mgw/authenticators/jwt", ["exports", "ember-simple-auth-token/authenticators/jwt"], function (_exports, _jwt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jwt.default.extend({
    session: Ember.inject.service(),
    authenticate: function authenticate(credentials, headers) {
      this.session.set('needsSync', true);
      return this._super(credentials, headers);
    },
    makeRefreshData: function makeRefreshData(refreshToken) {
      var _this$session$data, _this$session$data$au, _JSON$parse$authentic;

      var data = {};
      var nestings = this.refreshTokenPropertyName.split('.');
      var refreshTokenPropertyName = nestings.pop();
      var lastObject = data;
      nestings.forEach(function (nesting) {
        lastObject[nesting] = {};
        lastObject = lastObject[nesting];
      });
      lastObject[refreshTokenPropertyName] = refreshToken; // The server needs the current id/acess token to identify the user

      data.token = ((_this$session$data = this.session.data) === null || _this$session$data === void 0 ? void 0 : (_this$session$data$au = _this$session$data.authenticated) === null || _this$session$data$au === void 0 ? void 0 : _this$session$data$au.idToken) || ((_JSON$parse$authentic = JSON.parse(window.localStorage.getItem('ember_simple_auth-session')).authenticated) === null || _JSON$parse$authentic === void 0 ? void 0 : _JSON$parse$authentic.idToken);
      return data;
    }
  });

  _exports.default = _default;
});